import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import Tracking from '~/components/Abstracts/Tracking'
import { WithoutAuthenticationLogin } from '~/components/Account/Unlogged'

import getAuthenticationPageStaticPropsFunction from '~/data/authentication-page-data'
import { AuthenticationPageProps } from '~/data/authentication-page-data/serializer'

function LoginPage(props: AuthenticationPageProps) {
  const { login, backgroundImage } = props

  return (
    <>
      <WithoutAuthenticationLogin
        {...login}
        backgroundImage={backgroundImage}
      />
      <Tracking template="customers/login" />
    </>
  )
}

export default LoginPage

export const getStaticProps = getAuthenticationPageStaticPropsFunction({
  type: STORYBLOK_TYPES.LOGIN,
})
